<template>
  <RevForm
    :initial-values="initialValues"
    method="POST"
    :validate="validate()"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <RevCard class="p-24">
        <div class="heading-3 mb-12 md:mb-24">
          {{ i18n(translations.shippingAddressTitle) }}
        </div>
        <DynamicAddressFieldValidatorsErrorLogger
          :errors="errors"
          :form-values="values"
          logger-scope="CHECKOUT_SHIPPING"
        />
        <AddressFields
          v-model:city="values.city"
          v-model:company="values.company"
          v-model:country="values.country"
          v-model:first-name="values.firstName"
          v-model:first-name-pronunciation="values.firstNamePronunciation"
          v-model:last-name="values.lastName"
          v-model:last-name-pronunciation="values.lastNamePronunciation"
          v-model:national-id="values.nationalId"
          v-model:phone="values.phone"
          v-model:postal-code="values.postalCode"
          v-model:state-or-province="values.stateOrProvince"
          v-model:street="values.street"
          v-model:street2="values.street2"
          :countries="shippableCountries"
          :default-country="countryCode"
          :errors="getErrors(values, errors)"
          :feature-code="FeatureCode.WEB_CHECKOUT_SHIPPING"
          :optional-fields="['company']"
          :phone-help-message="i18n(translations.phoneHelperMessageAlternative)"
          shipping-state
          @autocompleted="handleAddressAutocomplete"
          @update:postal-code="handlePostalCodeUpdate"
          @update:street="handleStreetUpdate"
        />

        <RevInfoBlock
          v-if="shouldDisplayConfirmationWarning"
          class="mt-7"
          :content="i18n(translations.confirmationWarningContent)"
          data-qa="confirmation-warning"
          :icon="IconWarning"
          :title="i18n(translations.confirmationWarningTitle)"
          variant="warning"
        >
          <Address
            :address="values"
            class="mt-16"
            compact
            :with-phone="false"
            withDetails
          />
        </RevInfoBlock>

        <RevInfoBlock
          v-if="shouldDisplayStreetNumberWarning"
          class="mt-7"
          :content="i18n(translations.streetNumberWarningContent)"
          :icon="IconWarning"
          :title="i18n(translations.streetNumberWarningTitle)"
          variant="warning"
        />

        <div v-if="shouldDisplayUserInformation(values.country as Country)">
          <RevDivider class="my-24" />
          <h2 class="heading-3 mb-16">
            {{ i18n(translations.userInformationTitle) }}
          </h2>

          <UserInformationFields
            v-model:birthdate="values.birthdate"
            v-model:national-id="values.nationalId"
            :country="values.country"
            :errors="errors"
            :with-birthdate="withUserInformation"
            :with-national-id="
              userInformationWithNationalId(values.country as Country)
            "
          />

          <slot v-bind="{ country: values.country }" />

          <RevCheckbox
            id="sameAsShipping"
            v-model="sameAsShipping"
            class="mt-40 md:mt-24"
          >
            <template #label>
              {{ i18n(translations.sameBillingAddressLabel) }}
            </template>
          </RevCheckbox>

          <div class="mt-16 flex flex-col items-stretch md:items-end">
            <RevButton
              data-qa="submit-button"
              :disabled="submitting"
              full-width="responsive"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.submit) }}
            </RevButton>
          </div>
        </div>

        <div v-if="!shouldDisplayUserInformation(values.country as Country)">
          <slot v-bind="{ country: values.country }" />
        </div>

        <div
          v-if="
            !withUserInformation &&
            !shouldDisplayUserInformation(values.country as Country)
          "
          class="mt-40 flex flex-col items-stretch md:mt-24 md:items-end"
        >
          <RevCheckbox
            id="sameAsShipping"
            v-model="sameAsShipping"
            class="self-start"
          >
            <template #label>
              {{ i18n(translations.sameBillingAddressLabel) }}
            </template>
          </RevCheckbox>

          <RevButton
            class="mt-16"
            data-qa="submit-button"
            :disabled="submitting"
            full-width="responsive"
            type="submit"
            variant="primary"
          >
            {{ i18n(translations.submit) }}
          </RevButton>
        </div>
      </RevCard>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'

import { Country, MarketCountryCode } from '@backmarket/http-api'
import type {
  Bill,
  Deliver,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import type { AddressFieldsValues } from '@backmarket/http-api/src/api-specs-shipping/address/address-validation.types'
import Address from '@backmarket/nuxt-module-address/Address.vue'
import AddressFields from '@backmarket/nuxt-module-address/AddressFields.vue'
import { AddressFieldsValidators } from '@backmarket/nuxt-module-address/AddressFieldsValidators'
import DynamicAddressFieldValidatorsErrorLogger from '@backmarket/nuxt-module-address/DynamicAddressFieldValidatorsErrorLogger.vue'
import { InputAddressBirthdateValidators } from '@backmarket/nuxt-module-address/InputAddressBirthdateValidators'
// import { InputAddressNationalIdValidators } from '@backmarket/nuxt-module-address/InputAddressNationalIdValidators'
import UserInformationFields from '@backmarket/nuxt-module-address/UserInformationFields.vue'
import { FeatureCode } from '@backmarket/nuxt-module-address/featureCode'
import { useDynamicAddressFieldValidators } from '@backmarket/nuxt-module-address/useDynamicAddressFieldValidators'
import { isNationalIdShownForBilling } from '@backmarket/nuxt-module-address/utils/nationalId/isNationalIdShownForBilling'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevDivider } from '@ds/components/Divider'
import {
  RevForm,
  type ValidatorDeclaration,
  makeValidate,
} from '@ds/components/Form'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconWarning } from '@ds/icons/IconWarning'

import { CHECKOUT_LOG_TYPES } from '~/scopes/checkout/config/constants'
import { useUserInformationStore } from '~/scopes/checkout/stores/userInformationStore'
import {
  shouldValidatePostalCodeIfAutocompleted,
  shouldValidateStreetIfAutocompleted,
  shouldValidateStreetNumber,
} from '~/scopes/checkout/utils/autocomplete'

import translations from './ShippingAddressForm.translations'

const props = withDefaults(
  defineProps<{
    submitting?: boolean
    address: Deliver | Bill
    apiErrors: { [key: string]: string }
    countries: {
      value: string
      label: string
    }[]
    withUserInformation?: boolean
  }>(),
  {
    submitting: false,
    withUserInformation: false,
  },
)

const i18n = useI18n()
const tracking = useTracking()
const {
  market: { countryCode },
} = useMarketplace()

const userInformationStore = useUserInformationStore()
const { getDynamicAddressFieldValidators } = useDynamicAddressFieldValidators()

type FormValues = {
  city: string
  company: string
  country: Country | MarketCountryCode
  firstName: string
  lastName: string
  nationalId?: string
  phone: string
  postalCode: string
  stateOrProvince: string
  street: string
  street2: string
  birthdate?: string
  firstNamePronunciation?: string
  lastNamePronunciation?: string
}
const initialValues = reactive<FormValues>({
  city: props.address.city ?? '',
  company: props.address.company ?? '',
  country: props.countries.some(({ value }) => value === props.address.country)
    ? (props.address.country as Country)
    : countryCode,
  firstName: props.address.firstName ?? '',
  lastName: props.address.lastName ?? '',
  nationalId: props.address.customerIdNumber ?? '',
  phone: props.address.phone ?? '',
  postalCode: props.address.postalCode ?? '',
  stateOrProvince: props.address.stateOrProvince ?? '',
  street: props.address.street ?? '',
  street2: props.address.street2 ?? '',
  ...insertIf(userInformationStore.isFormRequired, {
    birthdate: props.address.birthdate ?? '',
  }),
  ...insertIf(props.address.country === Country.JP, {
    firstNamePronunciation: props.address.firstNamePronunciation ?? '',
    lastNamePronunciation: props.address.lastNamePronunciation ?? '',
  }),
})

const isAddressAutocompleted = ref(false)
const isAddressPotentiallyWrong = ref(false)
const isSubmittedOnce = ref(false)
const sameAsShipping = ref(true)
const shouldDisplayStreetNumberWarning = ref(false)

const emit = defineEmits(['submit'])

const shouldDisplayConfirmationWarning = computed(() => {
  return isAddressPotentiallyWrong.value && isSubmittedOnce.value
})

// Need to fix nationalId validation
const fallbackValidators: ValidatorDeclaration<AddressFieldsValues> = {
  ...AddressFieldsValidators(i18n),
  birthdate: InputAddressBirthdateValidators(i18n),
  // ...insertIf(sameAsShipping.value, {
  // nationalId: InputAddressNationalIdValidators(i18n),
  // }),
}

const addressValidation = await getDynamicAddressFieldValidators({
  featureFlag: 'FF_ENABLE_ADDRESS_FIELD_VALIDATION_CHECKOUT_SHIPPING',
  initialFormValues: initialValues,
  fallbackValidators,
  metadata: {
    scope: 'customer',
  },
})

function validate() {
  return makeValidate<FormValues>(addressValidation)
}

const shippableCountries = computed(() => {
  if (isEmpty(props.countries)) {
    return [
      {
        label: i18n.country(countryCode),
        value: countryCode,
      },
    ]
  }

  return props.countries
})

const userInformationWithNationalId = (selectedCountry: Country) => {
  return sameAsShipping.value && isNationalIdShownForBilling(selectedCountry)
}

const getErrors = (
  values: typeof initialValues,
  errors: Partial<Record<keyof FormValues, string>>,
) => {
  const tempErrors = { ...errors, ...props.apiErrors }

  if (userInformationWithNationalId(values.country as Country)) {
    delete tempErrors.nationalId
  }

  return tempErrors
}

const handleSubmit = (values: FormValues) => {
  if (shouldDisplayConfirmationWarning.value) {
    isAddressPotentiallyWrong.value = false
  }

  isSubmittedOnce.value = true

  if (!isAddressPotentiallyWrong.value) {
    emit(
      'submit',
      {
        city: values.city,
        company: values.company,
        country: values.country,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        postalCode: values.postalCode,
        stateOrProvince: values.stateOrProvince,
        street: values.street,
        street2: values.street2,
        ...insertIf(userInformationStore.isFormRequired, {
          birthdate: values.birthdate,
        }),
        ...insertIf(props.address.country === Country.JP, {
          firstNamePronunciation: values.firstNamePronunciation,
          lastNamePronunciation: values.lastNamePronunciation,
        }),
        ...insertIf(
          sameAsShipping.value &&
            isNationalIdShownForBilling(values.country as Country) &&
            !isEmpty(values.nationalId),
          {
            customerIdNumber: values.nationalId,
          },
        ),
      },
      sameAsShipping.value,
    )
  }
}

const handleAddressAutocomplete = (
  address: { [key: string]: string },
  index: number,
) => {
  isAddressAutocompleted.value = true
  isAddressPotentiallyWrong.value = false

  if (shouldValidateStreetNumber({ country: countryCode })) {
    shouldDisplayStreetNumberWarning.value = !/\d/.test(address.street)
  }

  tracking.trackChange({
    name: '[CHECKOUT] Address autocomplete',
    optionIndex: index,
    address,
    isShipping: true,
    isBilling: sameAsShipping.value,
    isCollectionPoint: false,
    type: CHECKOUT_LOG_TYPES.ADDRESS_AUTOCOMPLETE,
  })
}

const handleStreetUpdate = () => {
  if (shouldValidateStreetIfAutocompleted({ country: countryCode })) {
    isAddressAutocompleted.value = false
    isAddressPotentiallyWrong.value = true
  }
}

const handlePostalCodeUpdate = () => {
  if (shouldValidatePostalCodeIfAutocompleted({ country: countryCode })) {
    isAddressAutocompleted.value = false
    isAddressPotentiallyWrong.value = true
  }
}

const shouldDisplayUserInformation = (country: Country) => {
  return props.withUserInformation || userInformationWithNationalId(country)
}
</script>
