import type { MarketCountryCode } from '@backmarket/http-api'

import {
  VALIDATE_POSTAL_CODE_AUTOCOMPLETED,
  VALIDATE_STREET_AUTOCOMPLETED,
  VALIDATE_STREET_NUMBER,
} from '../config/autocomplete'

const shouldValidateGenerator =
  (dictionary: { [key: string]: boolean; default: boolean }) =>
  ({ country }: { country: MarketCountryCode }) =>
    dictionary[country] ?? dictionary.default

export const shouldValidateStreetNumber = shouldValidateGenerator(
  VALIDATE_STREET_NUMBER,
)

export const shouldValidateStreetIfAutocompleted = shouldValidateGenerator(
  VALIDATE_STREET_AUTOCOMPLETED,
)

export const shouldValidatePostalCodeIfAutocompleted = shouldValidateGenerator(
  VALIDATE_POSTAL_CODE_AUTOCOMPLETED,
)
